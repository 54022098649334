<template>
  <section class="mt-2" v-if="user.information">
    <b-container>
      <b-row>
        <b-col cols="12" lg="6">
          
          <b-card no-body>
            <template v-slot:header>
              {{ $t("PROFILE") }}
            </template>
            <b-card-body class="p-0">
              <dl class="clearfix pb-2">
                <dt>{{ $t("NAME") }}</dt>
                <dd>{{ user.information.name }}</dd>
                <dt>{{ $t("EMAIL") }}</dt>
                <dd>{{ user.information.email }}</dd>
                <div v-if="user.information.is_admin == 0">
                  <dt>{{ $t("ORGANISATION.TITLE") }}</dt>
                  <dd>
                    {{ user.information.organisation.name }}
                  </dd>
                </div>
              </dl>
              <hr class="p-0 m-0 mt-2" />
              <div class="pb-2 mt-2">
                <a href="#" class="pl-2" v-b-toggle.my-sidebar>{{
                  $t("MANAGE")
                }}</a>
              </div>
            </b-card-body>
          </b-card>

          <b-card
            no-body
            v-if="user.information.is_admin > 0"
          >
            <template v-slot:header>
              {{ $t("ORGANISATION.TITLE") }}
            </template>

            <b-card-body class="p-0" v-if="organisationStatistics && organisationInformation">
              <dl class="clearfix pb-2">
                <dt>{{ $t("NAME") }}</dt>
                <dd>{{ organisationInformation.name }}</dd>
                <dt>{{ $t("ORGANISATION.UNIQUE_IDENTIFIER") }}</dt>
                <dd>{{ organisationInformation.unique_identifier }}</dd>
                <dt>{{ $t("LICENSE.ACCOUNTS") }}</dt>
                <dd>{{ organisationStatistics.active_users }}</dd>
                <div v-if="1 == 0">
                  <dt>{{ $t("SENT_MESSAGES") }}</dt>
                  <dd>
                    {{ organisationStatistics.sent_messages }}
                    <span v-if="organisationStatistics.sent_messages_link != 0"
                      >({{ $t("SENT_MESSAGES_LINK") }}
                      {{ organisationStatistics.sent_messages_link }})</span
                    >
                  </dd>
                </div>
                <dt>{{ $t("STORAGE") }}</dt>
                <dd>
                  {{ bytesToSize(organisationStatistics.current_storage) }}
                  <span v-if="organisationInformation.total_storage != 0">
                    /
                    {{
                      bytesToSize(organisationInformation.total_storage)
                    }}</span
                  >
                </dd>
              </dl>
              <hr class="p-0 m-0 mt-2" />
              <div class="pb-2 mt-2">
                <router-link
                  :to="{
                    name: 'Administration.Organisation.Information',
                    params: {
                      organisation_id: user.information.organisation.id,
                    },
                  }"
                  class="pl-2"
                >
                  {{ $t("MANAGE") }}
                </router-link>
                <AddInvite :organisation="user.information.organisation">
                  <a href="#" class="pr-2 pl-2">
                    {{ $t("ORGANISATION.ADD_USER") }}</a
                  >
                </AddInvite>
              </div>
            </b-card-body>
          </b-card>

          <b-card
            no-body
            v-if="user.information.is_admin == 2"
          >
            <template v-slot:header>
              {{ $t("ADMINISTRATION.TITLE") }}
            </template>

            <b-card-body class="p-0" v-if="license">
              <dl class="clearfix pb-2">
                <dt>{{ $t("HOSTNAME") }}</dt>
                <dd>{{ license.hostname }}&nbsp;</dd>

                <dt>{{ $t("STORAGE") }}</dt>
                <dd>
                  {{ bytesToSize(applicationStatistics.storage) }}
                </dd>
              </dl>
              <hr class="p-0 m-0 mt-2" />
              <div class="pb-2 mt-2">
                <AddOrganisation
                  @invitedResult="addedOrganisation"
                  v-if="license.multitenant"
                  :key="addAdministrationOrganisationKey"
                >
                  <a href="#" class="pl-2 pr-2">{{
                    $t("ADMINISTRATION.ADD_ORGANISATION")
                  }}</a>
                </AddOrganisation>
                <AddNode
                  @addedNode="addedNode"
                  :license="license"
                  v-if="license.type == 'PA'"
                  :key="addNodeKey"
                >
                  <a href="#" class="pr-2">{{ $t("NODE.ADD_NODE") }}</a>
                </AddNode>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" lg="6">

          <b-card no-body v-if="sefosEnabled">
            <template v-slot:header>
              {{ $t("MESSAGES.INBOX") }} <br /><span class="f14">{{user.information.email}}</span>
            </template>
            <b-card-body class="p-0">
               <b-table
                small
                bordered
                :items="items"
                :tbody-tr-class="rowClass"
                hover          
                :fields="fields"
                 @row-clicked="clickedRow"
              >
                <template v-slot:cell(created_date)="data">{{
                  FormatDateTime(data.item.created_date)
                }}</template>

                <template v-slot:cell(size)="data">
                  {{ bytesToSize(data.item.size) }}
                </template>

                <template v-slot:cell(has_attachment)="data">
                  <i v-if="data.item.response_to_message_uuid == 'notyet'" class="mr-1 fal fa-comment" />
                  <i v-if="data.item.has_attachment" class="mr-1 fal fa-paperclip" />
                  <i v-if="data.item.claimed" class="fal fa-file-exclamation" :title="data.item.claimed" />
                </template>

                <template v-slot:cell(id)="data">
                  <span v-if="data.item.has_attachment"
                    ><i class="fal fa-paperclip"
                  /></span>
                  <div class="text-break">{{ data.item.subject }}</div>
                  <div class="time-information f12 text-break">{{FormatDateTime(data.item.created_date)}}</div>
                  <div class="f12">{{ data.item.from_email }}</div>
                </template>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
              </b-table>
              <div class="pb-2">
                 <router-link class="pl-2 pr-2" :to="{  name: 'Messages.Inbox',
                params: { email: user.information.email } }">{{
                  $t("DASHBOARD.GOTO_MESSAGES")
                }}</router-link>
              </div>
            </b-card-body>
          </b-card>
                  </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import AddNode from "@/components/Administration/AddNode";
import AddOrganisation from "@/components/Administration/AddOrganisation";
import AddInvite from "@/components/Invite/Add";
export default {
  components: { AddInvite, AddOrganisation, AddNode },
  data() {
    return {
      organisationInformation: {},
      organisationStatistics: {},
      applicationStatistics: {},
      addOrganisationKey: 1000,
      addNodeKey: 2000,
      addAdministrationOrganisationKey: 3000,
      license: null,
      userInformation: {},
      links: [],
      items:[],
      sefosEnabled: false,
      fields: [
        {
          key: "id",
          class: "clickable text-break",
          thClass: "hidden"
        }
      ]
    };
  },
  methods: {
    async checkSefosEnabled(){      
      let self = this;
      this.$http
        .post(this.user.hostname + "/sefos-message/enabled",{
          organisation_uuid: this.user.information.organisation.organisation_uuid,
          functionbox_uuid: ""
        })
        .then(function(result) {
          self.sefosEnabled = result.data;
          if(result.data)
          {            
            self.getInbox();
          }
        })
        .catch(function() {
          console.log("BANAN");
          self.sefosEnabled = false;
        });
    },
    rowClass(item, type) {
      if (item && type === "row") {
        let returnClass = "";
        if(item.claimed != null)
        {
          returnClass = "claimed";
        } 
        if (item.fetched === 0) {
          returnClass += " not-viewed";
        }
        return returnClass;
      }
      return "";
    },
    clickedRow(data) {
      this.$router.push({ name: 'Messages.Inbox',  params: { view_message_uuid: data.message_uuid, email: this.user.information.email } }).catch(() => {});
    },
    addedOrganisation: function() {
      this.addOrganisationKey = this.addOrganisationKey + 1;
      this.addAdministrationOrganisationKey =
        this.addAdministrationOrganisationKey + 1;
    },
    addedNode: function() {
      this.addNodeKey = this.addNodeKey + 1;
    },
    getOrganisationInformation: function() {
      let self = this;
      if (this.user.token != "") {
        this.$http
          .get(
            this.user.hostname + "/organisation/information/" +
              this.user.information.organisation.id
          )
          .then(function(response){
            self.organisationInformation = response.data;
          })
          .catch(function(){
          });
      }
    },
    getInbox: function() {
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/inbox/list",
          {
            functionbox_uuid: '',
            page: 1,
            limit: 5,
            state: 1,
            search_text: '',
          }
        )
        .then((response) => {
          self.items = response.data.items;
        })
        .catch(() => {
        });
    },
    getOrganisationStatistic: function() {
      let self = this;
      this.$http
        .get(
          this.user.hostname + "/organisation/statistics/" +
            this.user.information.organisation.id
        )
        .then(function(response){
          self.organisationStatistics = response.data;
        })
        .catch(function(){
        });
    },
    getApplicationStatistic: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/server-metrics")
        .then(function(response){
          self.applicationStatistics = response.data;
        })
        .catch(function(){
        });
    },
    getLicense: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/license")
        .then(function(response){
          self.license = response.data;
        })
        .catch(function(){
        });
    },
    getUserInformation: function() {
      let self = this;
      this.$http
        .get(
          this.user.hostname + "/user/information/" + this.user.information.user_uuid
        )
        .then(function(response){
          self.userInformation = response.data;
        })
        .catch(function(){
        });
    },
    showAdmin: function(state) {
      return state === 0 ? this.$t("NO") : this.$t("YES");
    },
  },
  computed: {
  },
  mounted() {
    if (this.user.information != null) {
      this.checkSefosEnabled();
      if (this.user.information.is_admin > 0) {
        this.getOrganisationInformation();
        this.getOrganisationStatistic();
        this.getUserInformation();
      }
      if (this.user.information.is_admin == 2) {
        this.getApplicationStatistic();
        this.getLicense();
      }
    }

    let users = [
      {
        email: "test1@banan.com"
      },
      {
        email: "test2@banan.com"
      },
      {
        email: "test1@banan.com"
      },
      {
        email: "test3@banan.com"
      }
    ];
    var flags = [], output = [], l = users.length, i;
    for( i=0; i<l; i++) {
        if( flags[users[i].email]) continue;
        flags[users[i].email] = true;
        output.push(users[i]);
    }
    console.log(users);
    console.log(output);
  },
};
</script>
<style></style>
