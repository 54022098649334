var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "6" } },
                    [
                      _c(
                        "b-card",
                        {
                          attrs: { "no-body": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("PROFILE")) +
                                        "\n          "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            404346133
                          ),
                        },
                        [
                          _c("b-card-body", { staticClass: "p-0" }, [
                            _c("dl", { staticClass: "clearfix pb-2" }, [
                              _c("dt", [_vm._v(_vm._s(_vm.$t("NAME")))]),
                              _c("dd", [
                                _vm._v(_vm._s(_vm.user.information.name)),
                              ]),
                              _c("dt", [_vm._v(_vm._s(_vm.$t("EMAIL")))]),
                              _c("dd", [
                                _vm._v(_vm._s(_vm.user.information.email)),
                              ]),
                              _vm.user.information.is_admin == 0
                                ? _c("div", [
                                    _c("dt", [
                                      _vm._v(
                                        _vm._s(_vm.$t("ORGANISATION.TITLE"))
                                      ),
                                    ]),
                                    _c("dd", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.user.information.organisation
                                              .name
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("hr", { staticClass: "p-0 m-0 mt-2" }),
                            _c("div", { staticClass: "pb-2 mt-2" }, [
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle.my-sidebar",
                                      modifiers: { "my-sidebar": true },
                                    },
                                  ],
                                  staticClass: "pl-2",
                                  attrs: { href: "#" },
                                },
                                [_vm._v(_vm._s(_vm.$t("MANAGE")))]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm.user.information.is_admin > 0
                        ? _c(
                            "b-card",
                            {
                              attrs: { "no-body": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t("ORGANISATION.TITLE")
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3027373538
                              ),
                            },
                            [
                              _vm.organisationStatistics &&
                              _vm.organisationInformation
                                ? _c("b-card-body", { staticClass: "p-0" }, [
                                    _c("dl", { staticClass: "clearfix pb-2" }, [
                                      _c("dt", [
                                        _vm._v(_vm._s(_vm.$t("NAME"))),
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.organisationInformation.name
                                          )
                                        ),
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "ORGANISATION.UNIQUE_IDENTIFIER"
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.organisationInformation
                                              .unique_identifier
                                          )
                                        ),
                                      ]),
                                      _c("dt", [
                                        _vm._v(
                                          _vm._s(_vm.$t("LICENSE.ACCOUNTS"))
                                        ),
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.organisationStatistics
                                              .active_users
                                          )
                                        ),
                                      ]),
                                      1 == 0
                                        ? _c("div", [
                                            _c("dt", [
                                              _vm._v(
                                                _vm._s(_vm.$t("SENT_MESSAGES"))
                                              ),
                                            ]),
                                            _c("dd", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.organisationStatistics
                                                      .sent_messages
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _vm.organisationStatistics
                                                .sent_messages_link != 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "SENT_MESSAGES_LINK"
                                                          )
                                                        ) +
                                                        "\n                    " +
                                                        _vm._s(
                                                          _vm
                                                            .organisationStatistics
                                                            .sent_messages_link
                                                        ) +
                                                        ")"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                          ])
                                        : _vm._e(),
                                      _c("dt", [
                                        _vm._v(_vm._s(_vm.$t("STORAGE"))),
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.bytesToSize(
                                                _vm.organisationStatistics
                                                  .current_storage
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                        _vm.organisationInformation
                                          .total_storage != 0
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                  /\n                  " +
                                                  _vm._s(
                                                    _vm.bytesToSize(
                                                      _vm
                                                        .organisationInformation
                                                        .total_storage
                                                    )
                                                  )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ]),
                                    _c("hr", { staticClass: "p-0 m-0 mt-2" }),
                                    _c(
                                      "div",
                                      { staticClass: "pb-2 mt-2" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "pl-2",
                                            attrs: {
                                              to: {
                                                name: "Administration.Organisation.Information",
                                                params: {
                                                  organisation_id:
                                                    _vm.user.information
                                                      .organisation.id,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("MANAGE")) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "AddInvite",
                                          {
                                            attrs: {
                                              organisation:
                                                _vm.user.information
                                                  .organisation,
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "pr-2 pl-2",
                                                attrs: { href: "#" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "ORGANISATION.ADD_USER"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.user.information.is_admin == 2
                        ? _c(
                            "b-card",
                            {
                              attrs: { "no-body": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t("ADMINISTRATION.TITLE")
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1547272446
                              ),
                            },
                            [
                              _vm.license
                                ? _c("b-card-body", { staticClass: "p-0" }, [
                                    _c("dl", { staticClass: "clearfix pb-2" }, [
                                      _c("dt", [
                                        _vm._v(_vm._s(_vm.$t("HOSTNAME"))),
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          _vm._s(_vm.license.hostname) + " "
                                        ),
                                      ]),
                                      _c("dt", [
                                        _vm._v(_vm._s(_vm.$t("STORAGE"))),
                                      ]),
                                      _c("dd", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.bytesToSize(
                                                _vm.applicationStatistics
                                                  .storage
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                    ]),
                                    _c("hr", { staticClass: "p-0 m-0 mt-2" }),
                                    _c(
                                      "div",
                                      { staticClass: "pb-2 mt-2" },
                                      [
                                        _vm.license.multitenant
                                          ? _c(
                                              "AddOrganisation",
                                              {
                                                key: _vm.addAdministrationOrganisationKey,
                                                on: {
                                                  invitedResult:
                                                    _vm.addedOrganisation,
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "pl-2 pr-2",
                                                    attrs: { href: "#" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "ADMINISTRATION.ADD_ORGANISATION"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.license.type == "PA"
                                          ? _c(
                                              "AddNode",
                                              {
                                                key: _vm.addNodeKey,
                                                attrs: { license: _vm.license },
                                                on: {
                                                  addedNode: _vm.addedNode,
                                                },
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "pr-2",
                                                    attrs: { href: "#" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("NODE.ADD_NODE")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "6" } },
                    [
                      _vm.sefosEnabled
                        ? _c(
                            "b-card",
                            {
                              attrs: { "no-body": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t("MESSAGES.INBOX")) +
                                            " "
                                        ),
                                        _c("br"),
                                        _c("span", { staticClass: "f14" }, [
                                          _vm._v(
                                            _vm._s(_vm.user.information.email)
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1220197551
                              ),
                            },
                            [
                              _c(
                                "b-card-body",
                                { staticClass: "p-0" },
                                [
                                  _c("b-table", {
                                    attrs: {
                                      small: "",
                                      bordered: "",
                                      items: _vm.items,
                                      "tbody-tr-class": _vm.rowClass,
                                      hover: "",
                                      fields: _vm.fields,
                                    },
                                    on: { "row-clicked": _vm.clickedRow },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(created_date)",
                                          fn: function (data) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.FormatDateTime(
                                                    data.item.created_date
                                                  )
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(size)",
                                          fn: function (data) {
                                            return [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.bytesToSize(
                                                      data.item.size
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(has_attachment)",
                                          fn: function (data) {
                                            return [
                                              data.item
                                                .response_to_message_uuid ==
                                              "notyet"
                                                ? _c("i", {
                                                    staticClass:
                                                      "mr-1 fal fa-comment",
                                                  })
                                                : _vm._e(),
                                              data.item.has_attachment
                                                ? _c("i", {
                                                    staticClass:
                                                      "mr-1 fal fa-paperclip",
                                                  })
                                                : _vm._e(),
                                              data.item.claimed
                                                ? _c("i", {
                                                    staticClass:
                                                      "fal fa-file-exclamation",
                                                    attrs: {
                                                      title: data.item.claimed,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(id)",
                                          fn: function (data) {
                                            return [
                                              data.item.has_attachment
                                                ? _c("span", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fal fa-paperclip",
                                                    }),
                                                  ])
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "text-break" },
                                                [
                                                  _vm._v(
                                                    _vm._s(data.item.subject)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "time-information f12 text-break",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormatDateTime(
                                                        data.item.created_date
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "f12" },
                                                [
                                                  _vm._v(
                                                    _vm._s(data.item.from_email)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                        {
                                          key: "table-busy",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-center my-2",
                                                },
                                                [
                                                  _c("b-spinner", {
                                                    staticClass: "align-middle",
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3489502460
                                    ),
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "pb-2" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "pl-2 pr-2",
                                          attrs: {
                                            to: {
                                              name: "Messages.Inbox",
                                              params: {
                                                email:
                                                  _vm.user.information.email,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("DASHBOARD.GOTO_MESSAGES")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }